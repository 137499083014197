import { FeatureToggleClientService } from 'feature-toggle-client';

export interface Configuration {
  ldClientSdkKey: string;
  ldProxyBaseUrl: string;
  ldProxyEventsUrl: string;
  ldProxyClientStream: string;
  ldStreamReconnectDelay: number;
  startDateFilterLimit?: number;
}

class FeatureToggle {
  public async InitAnonymousFeatureToggle() {
    const LDConfig: Configuration = {
        ldClientSdkKey: process.env.REACT_APP_LAUNCHDARKLY_CLIENTSDKKEY || '',
        ldProxyBaseUrl: process.env.REACT_APP_LAUNCHDARKLY_BASEURL || '',
        ldProxyEventsUrl: process.env.REACT_APP_LAUNCHDARKLY_EVENTSURL || '',
        ldProxyClientStream: process.env.REACT_APP_LAUNCHDARKLY_STREAMURL || '',
        ldStreamReconnectDelay: Number(process.env.REACT_APP_LAUNCHDARKLY_STREAMRECONNECTDELAY) || 0,
    } 
    const featureToggleInstance = FeatureToggleClientService.getInstance();
    featureToggleInstance.initializeUser( {anonymous: true}, LDConfig.ldClientSdkKey, {
        baseUrl: LDConfig.ldProxyBaseUrl,
        eventsUrl: LDConfig.ldProxyEventsUrl,
        streamUrl: LDConfig.ldProxyClientStream,
        streamReconnectDelay: LDConfig.ldStreamReconnectDelay,
    });
    await featureToggleInstance.getUserInstance().waitUntilReady();
  }

  public async isFeatureEnabled(feature: string): Promise<boolean> {
    return await FeatureToggleClientService.getInstance().isFeatureEnabled(feature);
  }

  public async isFeatureGetValue<T>(feature: string): Promise<T> {
    return await FeatureToggleClientService.getInstance().isFeatureEnabled(feature);
  }
}

export default new FeatureToggle();
