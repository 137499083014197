import React, { createContext, Dispatch, SetStateAction, useContext, useState, useCallback } from 'react';
import { MenuStructure } from 'typings/MenuStructure';
import FeatureToggle from 'services/FeatureToggle';

type AppContextValue = {
  menuStructure: MenuStructure;
  setMenuStructure: Dispatch<SetStateAction<MenuStructure>>;
  setLdClientInit: () => Promise<void>;
};

export const AppContext = createContext<AppContextValue>({} as AppContextValue);

export const AppProvider: React.FC = ({ children }) => {
  const [menuStructure, setMenuStructure] = useState<MenuStructure>([]);

  const setLdClientInit = useCallback(async () => {
    await FeatureToggle.InitAnonymousFeatureToggle();
  },[]); 

  return (
    <AppContext.Provider value={{menuStructure, setMenuStructure, setLdClientInit }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
