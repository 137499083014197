// import { defineCustomElements } from 'blip-ds/loader';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AppProvider } from 'contexts/AppContext';

// defineCustomElements(window);

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
