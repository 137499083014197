import { GlobalStyles } from 'globalStyles';
import { usePluginEventsHandler } from 'hooks/usePluginEventsHandler';
import { useAuth } from 'oidc-react';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AppCuesService } from 'services/AppCues';
import { identify } from 'services/Segment';

const MainContainer: FC = () => {
  usePluginEventsHandler();
  const auth = useAuth();

  useEffect(() => {
    if (auth.userData?.profile.email?.length) {
      identify(auth.userData.profile.email);

      AppCuesService.instance.identify(auth.userData.profile.email, {
        name: auth.userData.profile.name,
        email: auth.userData.profile.email,
        createdAt: new Date(),
      });

      setTimeout(() => AppCuesService.instance.start(), 1000);
    }
  }, [auth.userData?.profile]);

  return auth.userData ? (
    <>
      <GlobalStyles />
      <Outlet />
    </>
  ) : (
    <></>
  );
};

export default MainContainer;
